var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "公告详情",
        visible: _vm.dialogVisible,
        width: "960px",
        "custom-class": "showdetailbox",
        "close-on-click-modal": false,
        "before-close": _vm.learnReaded,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "showdetailboxconts" }, [
        _c("div", { staticClass: "showdetailboxcont" }, [
          _c("div", { staticClass: "titles" }, [
            _c("span", [_vm._v(_vm._s(_vm.formscontent.titleName || ""))]),
          ]),
          _c("div", { staticClass: "headers" }, [
            _c("div", [
              _c("p", [
                _vm._v(" " + _vm._s(_vm.formscontent.noticeTypeName) + " "),
              ]),
              _vm.formscontent.noticeType &&
              _vm.formscontent.noticeType === "SCENIC_NOTICE"
                ? _c("span", [
                    _vm._v(" 发布人员："),
                    _c("i", [
                      _vm._v(_vm._s(_vm.formscontent.createUserName || "")),
                    ]),
                  ])
                : _vm._e(),
              _c("span", [
                _vm._v("发布时间："),
                _c("i", [_vm._v(_vm._s(_vm.formscontent.updateTime || ""))]),
              ]),
              _vm.formscontent.noticeType &&
              _vm.formscontent.noticeType === "SCENIC_NOTICE"
                ? _c("span", { staticClass: "personinfo" }, [
                    _vm._v(" 接收人员："),
                    _c(
                      "i",
                      { attrs: { title: _vm.formscontent.description || "" } },
                      [_vm._v(_vm._s(_vm.formscontent.description || ""))]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "contents" }, [
            _c("div", {
              staticClass: "content",
              domProps: { innerHTML: _vm._s(_vm.formscontent.content) },
            }),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [{ name: "preventClick", rawName: "v-preventClick" }],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.sureReaded()
                },
              },
            },
            [_vm._v(" 我已知晓 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }