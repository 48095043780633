<template>
  <el-dialog
    title="公告详情"
    :visible.sync="dialogVisible"
    width="960px"
    custom-class="showdetailbox"
    :close-on-click-modal="false"
    :before-close="learnReaded">
    <div class="showdetailboxconts">
      <div class="showdetailboxcont">
        <div class="titles">
          <!-- <i></i> -->
          <span>{{ formscontent.titleName || '' }}</span>
        </div>
        <div class="headers">
          <div>
            <p>
              {{ formscontent.noticeTypeName }}
            </p>
            <span v-if="formscontent.noticeType && formscontent.noticeType === 'SCENIC_NOTICE'">
              发布人员：<i>{{ formscontent.createUserName || '' }}</i>
            </span>
            <span>发布时间：<i>{{ formscontent.updateTime || '' }}</i></span>
            <span v-if="formscontent.noticeType && formscontent.noticeType === 'SCENIC_NOTICE'"
                  class="personinfo">
              接收人员：<i :title="formscontent.description || ''">{{ formscontent.description || '' }}</i>
            </span>
          </div>
        </div>
        <div class="contents">
          <div class="content"
               v-html="formscontent.content">
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button
        v-preventClick
        type="primary"
        @click="sureReaded()">
        我已知晓
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'utils-lite';
export default {
  name: 'Showdetail',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    forms: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formscontent: {}
    };
  },
  ...mapGetters({
    showMessage: 'showMessage'
  }),
  watch: {
    showMessage: {
      handler(val) {
        this.formscontent = cloneDeep(val);
        console.log(this.formscontent);
      },
      deep: true
    },
    forms: {
      handler(val) {
        this.formscontent = cloneDeep(val);
        console.log(this.formscontent);
      },
      deep: true
    }
  },
  mounted() {
    // this.getDetail();
    this.formscontent = cloneDeep(this.forms);
  },
  methods: {
    // 关闭
    handleClose() {
      this.$emit('closeDiag', false);
    },
    // 消息已读
    sureReaded() {
      if (!this.formscontent.id) {
        return false;
      }
      this.$api.readMessage(this.formscontent.id).then((res) => {
        if (res.code === 0) {
          this.handleClose();
        } else {
          this.$notice.operateError('', res.message || '');
        }
        return false;
      })
        .catch((err) => {
          this.$notice.operateError('获取详情失败', err.message || '');
          return false;
        });
      return false;
    },
    // 消息关闭(未读)
    learnReaded() {
      if (!this.formscontent.id) {
        return false;
      }
      this.$api.noReadMessage(this.formscontent.id).then((res) => {
        if (res.code === 0) {
          this.handleClose();
        } else {
          this.handleClose();
          this.$notice.operateError('', res.message || '');
        }
        return false;
      })
        .catch((err) => {
          this.handleClose();
          this.$notice.operateError('获取详情失败', err.message || '');
          return false;
        });
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body{
  padding: 10px 30px 30px;
}
/deep/ .el-dialog__header{
  margin: 18px 20px;
  padding: 20px 20px 10px 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}
/deep/ .el-dialog__body{
  padding: 0;
}
.showdetailbox{
  .showdetailboxconts{
    height: 50vh;
    width: calc(100% - 5px);
    overflow: auto;
  }
  .showdetailboxcont{
    margin-bottom: 10px;
    padding: 0 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    font-size: $f16;
    color: #606266;
    .titles{
      line-height: 80px;
      width: 100%;
      min-height: 80px;
      font-size: 20px;
      position: relative;
      // text-indent: 10px;
      font-weight: bold;
      color: #1b1e40;
      text-align: center;
      i{
        position: absolute;
        left: 0;
        top: 10px;
        width: 6px;
        height: 30px;
        background: #409EFF;
      }
    }
    .headers{
      min-height: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin:5px 0 20px 0;
      >div{
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // background: #eee;
        // border: 1px solid #ddd;
        box-sizing: border-box;
        >p{
          color: rgb(94,108,255);
          margin-right: 20px;
          padding: 5px 10px;
          background: rgba(94,108,255, 0.1);
          border-radius: 5px;
        }
        >span{
          margin-right: 20px;
          font-size: $f14;
          color: #999;
          >i{
            font-style: normal;
            color: #666;
          }
        }
      }
      .personinfo{
        max-width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .contents{
      flex: 1;
      width: 100%;
      font-size: $f14;
      line-height: 24px;
      .content{
        width: 100%;
        height: auto;
      }
    }
  }
  .footer{
    width: 100%;
    height: 70px;
    line-height: 40px;
    text-align: center;
    padding: 10px 0 20px 0;
    border-top: 1px solid #ddd;
  }
}
</style>
