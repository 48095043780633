var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cp-frame" },
    [
      _c("div", { staticClass: "frame-btn-box" }, [
        _c(
          "div",
          { staticClass: "frame-btninfo", on: { click: _vm.windowReload } },
          [_vm._m(0)]
        ),
      ]),
      _c("cp-header", {
        attrs: {
          systems: _vm.systems,
          site: _vm.site,
          "index-router": _vm.indexPageRouter,
        },
      }),
      _vm.reloadiframe
        ? _c("iframe", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading.fullscreen.lock",
                value: _vm.loading,
                expression: "loading",
                modifiers: { fullscreen: true, lock: true },
              },
            ],
            staticClass: "container",
            attrs: {
              id: "containerFrm",
              "element-loading-text": "正在加载，请稍后",
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "rgba(0, 0, 0, 0.8)",
              src: _vm.subsystemUrl,
            },
          })
        : _vm._e(),
      _vm.dialogVisible
        ? _c("message", {
            attrs: { "dialog-visible": _vm.dialogVisible, forms: _vm.forms },
            on: { closeDiag: _vm.closeDiag },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "cp-icon" }, [_vm._v("")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }