<template>
  <div class="cp-frame">
    <div class="frame-btn-box">
      <div class="frame-btninfo" @click="windowReload">
        <span><i class="cp-icon">&#xe832;</i></span>
      </div>
      <!-- <div class="frame-btninfo"
           @click="windowBack">
        <span>后退</span>
      </div> -->
    </div>
    <cp-header
      :systems="systems"
      :site="site"
      :index-router="indexPageRouter"
    ></cp-header>
    <iframe
      v-if="reloadiframe"
      id="containerFrm"
      v-loading.fullscreen.lock="loading"
      class="container"
      element-loading-text="正在加载，请稍后"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :src="subsystemUrl"
    ></iframe>
    <message
      v-if="dialogVisible"
      :dialog-visible="dialogVisible"
      :forms="forms"
      @closeDiag="closeDiag"
    >
    </message>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import comm from "@/utils/comm";
import { MessageBox } from "element-ui";
import Message from "./components/message";
export default {
  name: "CpFrame",
  components: {
    Message
  },
  data() {
    return {
      reloadiframe: true,
      // 访问时判断是否已经存储了token，没有则需要获取系统和站点列表
      tokenExist: false,
      loading: true,
      // 主页路由，设置头部logo跳转
      indexPageRouter: "/index",
      // 子系统列表
      systems: [],
      // 当前子系统id访问地址
      currentSystemUrl: "",
      // 站点切换数据
      site: {
        // 集团站点
        groups: [],
        // 景区站点
        scenic: [],
        // 默认站点
        defaultCompany: {}
      },
      // 展示消息
      dialogVisible: false,
      forms: {},
      timeoutFlag: false
    };
  },
  beforeRouteEnter(to, from, next) {
    // 通过 `vm` 访问组件实例
    next(vm => {
      // 获取地址栏的token
      const token = sessionStorage.getItem("urlToken");
      vm.init(token, "enter");
    });
  },
  beforeRouteUpdate(to, from, next) {
    const token = sessionStorage.getItem("urlToken");
    this.init(token, "update");
    // 路由变化时，设置
    next();
  },
  computed: {
    ...mapGetters({
      token: "token",
      siteId: "siteId",
      refreshNum: "refreshNum",
      messageStatic: "messageStatic",
      showMessage: "showMessage",
      isThridSys: "isThridSys",
      systemUrl: "systemUrl"
    }),
    systemId: {
      get() {
        return this.$store.getters.systemId;
      },
      set(id) {
        this.$store.commit("systemId", id);
      }
    },
    subsystemUrl() {
      let currenturl = "";
      this.reloadiframe = false;
      if (this.currentSystemUrl) {
        if (this.currentSystemUrl.indexOf("?") !== -1) {
          currenturl = `${this.currentSystemUrl}&independent=0#${this.token}`;
        } else {
          currenturl = `${this.currentSystemUrl}index?independent=0#${this.token}`;
        }
      } else {
        currenturl = "";
      }
      setTimeout(() => {
        this.reloadiframe = true;
      }, 100);
      // currenturl = 'http://localhost:8082/informationdelivery/announcementinformation/index?independent=0';
      return currenturl;
    }
  },
  watch: {
    token(token) {
      // token变化必须重新获取
      if (token) {
        this.$store.dispatch("getUserInfo");
        this.getSystems();
        this.getSiteList();
      }
    },
    systemId(id) {
      if (this.isThridSys) {
        return;
      }
      if (!id) {
        return;
      }
      const system = this.systems.find(item => item.mId === id);
      if (system) {
        if (system.route) {
          this.currentSystemUrl = system.route;
        } else {
          this.$notice.popupError(
            "切换子系统失败",
            `请设置子系统‘${system.label}’的路由！`
          );
        }
      } else {
        this.$notice.popupError("切换子系统失败", "没有找到子系统");
      }
    },
    systemUrl(val) {
      if (val) {
        this.currentSystemUrl = val;
      }
    },
    subsystemUrl(value) {
      if (value) {
        this.loading = false;
      }
    },
    // siteId(id) {
    //   id && this.changeSite(id);
    // },
    refreshNum(val) {
      if (val) {
        this.getMessageInfos(val);
      }
    },
    showMessage() {
      // this.dialogVisible = true;
      this.getDetail();
    }
  },
  mounted() {
    window.childPageCallBack = this.childPageCallBack;
    this.getMessageInfos();
    this.getSystemInfo();
    // this.forms = {
    //   id: 256,
    //   titleName: '11111',
    //   noticeType: 'SCENIC_NOTICE',
    //   createUserName: '2222',
    //   updateTime: '3333',
    //   description: 'description',
    //   content: 'content'
    // };
    // this.dialogVisible = true;
    if (window.performance.navigation.type === 0) {
      // this.dialogVisible = true;
      this.getDetail();
    }
  },
  methods: {
    childPageCallBack() {
      if (this.timeoutFlag) return;
      this.timeoutFlag = true;
      MessageBox.alert(
        `<div class="daq-message-box">
          <p class="title">登录已过期</p>
          <p class="content">账号信息已过期或禁用，即将强制退出系统!</p>
        </div>`,
        {
          title: "提示",
          dangerouslyUseHTMLString: true,
          type: "error",
          confirmButtonText: '退出',
          showClose: false,
        }
      ).then(() => {
        top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
      });
    },

    // iframe刷新
    windowReload() {
      document
        .getElementById("containerFrm")
        .contentWindow.location.reload(true);
    },
    // iframe内页面后退
    windowBack() {
      document.getElementById("containerFrm").contentWindow.history.back();
    },
    // 获取详情
    getDetail() {
      this.$api
        .getLastMessage()
        .then(res => {
          if (res.code === 0) {
            if (!res.data) {
              // 消息已读，无最新消息
              this.forms = {};
              return false;
            } else {
              this.forms = res.data || {};
              this.dialogVisible = true;
            }
          } else {
            this.$notice.operateError("获取最新消息失败", res.message || "");
          }
          return false;
        })
        .catch(err => {
          this.$notice.operateError("获取最新消息失败", err.message || "");
        });
    },
    // 关闭
    closeDiag() {
      this.dialogVisible = false;
    },
    // 获取消息-统计信息
    getMessageInfos(param = false) {
      this.$api.getMessageInfos().then(res => {
        if (res.code === 0) {
          this.$store.commit("messageStatic", res.datas || []);
          if (param) {
            this.$store.commit("refreshNum", false);
          }
        }
      });
    },

    /**
     *获取系统默认favicon、logo、name并存储
     */
    getSystemInfo() {
      this.$api.getSystemInfo().then(res => {
        if (res.code === 0) {
          // 存储favicon
          this.$store.commit("system/set", {
            favicon: res.data.favUrl,
            name: res.data.title,
            logo: res.data.logoUrl,
            globallogo: res.data.logoUrl,
            globalfavicon: res.data.favUrl,
            globalname: res.data.title
          });
          comm.setWebsiteFavicon(res.data.favUrl);
        }
      });
    },

    /**
     * [初始化页面]
     * @param {[String]} token [传入的token]
     * @param {[String]} type [enter: 路由进入时，update: 路由更新时]
     * @returns
     */
    init(token, type) {
      // 只要刷新页面就必须重新获取（因为有可能获取失败，后期可以判断下）
      if (token && token !== this.token) {
        if (type === "update") {
          this.$store.commit("token", this.token);
          // 修复iframe与浏览器token不一致问题
          this.$nextTick(() => {
            const href = `${location.href.split("#")[0]}#${this.token}`;
            history.pushState(null, null, href);
          });
        } else {
          this.$store.commit("token", token);
        }
      } else if (this.token) {
        // 无token表示，使用存储的信息
        this.$store.dispatch("getUserInfo");
        this.getSystems();
        this.getSiteList();
      } else {
        this.$router.push({
          path: "/login"
        });
      }
    },
    /* eslint-disable */
    changeSite(id) {
      let site = this.site.scenic.find(
        item => item.id === id && item.accountId === this.user.account.id
      );
      if (!site) {
        site = this.site.groups.find(
          item => item.id === id && item.accountId === this.user.account.id
        );
        if (!site) {
          return;
        }
      }
      this.loading = true;

      // 切换站点，获取新的token
      this.$api
        .changeGroup({
          // authCode: this.$store.getters.authCode,
          accountId: site.accountId,
          vCode: site.vcode
        })
        .then(res => {
          this.loading = false;
          const tokenInfo = (res.data && res.data.token) || {};
          const token = tokenInfo.token;
          sessionStorage.setItem("urlToken", token);
          if (tokenInfo.token) {
            if (res.data.guidePageFlag) {
              // alert('3130')
              // 跳转到导览页
              this.$router.push({
                path: `/guide`
              });
            } else {
              // 景区版
              if (site.versionType === 2) {
                // 修复iframe与浏览器token不一致问题
                this.$nextTick(() => {
                  const href = `${location.href.split("#")[0]}#${
                    tokenInfo.token
                  }`;
                  history.pushState(null, null, href);
                });
                this.$store.commit("token", tokenInfo.token);
                this.getMessageInfos(true);
                // this.dialogVisible = true;
                this.getDetail();
              } else {
                // 集团，携带新token跳转到集团页面
                window.location.href = `${window.location.origin}/group/index#${tokenInfo.token}`;
              }
            }
          } else {
            this.$notice.operateError("切换站点失败", "获取token不存在");
          }
        })
        .catch(error => {
          this.loading = false;
          if (error.data) {
            this.$notice.operateError("切换站点失败", error.data.message);
          } else {
            // 判断http code
            this.$notice.operateError("切换站点失败", "后台异常");
          }
        });
    },
    getSystems() {
      // 获取菜单树
      this.$api
        .getSystemList()
        .then(res => {
          this.systems = res.datas || [];
          if (this.systems.length === 0) {
            this.currentSystemUrl = "";
            this.systemId = "";
            this.$notice.popupWarning(
              "无子系统数据",
              "请添加子系统或者添加子系统权限"
            );
            this.loading = false;
          } else {
            // 先根据记录获取对应系统，不存在则获取综合管理，综合管理不存在则获取第一个
            let system = this.systems.find(
              system => system.mId === this.systemId
            );
            if (!system) {
              system = this.systems.find(system => system.label === "综合管理");
              if (!system) {
                system = this.systems[0];
              }
            }
            if (system.route) {
              this.currentSystemUrl = system.route;
            } else if (this.systemId) {
              this.$notice.popupError(
                "访问子系统失败",
                `请设置子系统‘${system.label}’的路由！`
              );
            }
            this.systemId = system.mId;
          }
        })
        .catch(() => {
          this.loading = false;
          // 右下角提示改为ajax 403 统一处理
          // if (error.data) {
          //   this.$notice.operateError('获取菜单列表失败', error.data.message);
          // } else {
          //   // 判断http code
          //   this.$notice.operateError('获取菜单列表失败', '后台异常');
          // }
        });
    },
    getSiteList() {
      // 获取站点列表
      this.$api
        .getSiteList()
        .then(res => {
          const data = res.data;
          this.site.groups = data.groups || [];
          this.site.scenic = data.scenic || [];

          if (data.defaultCompany) {
            this.site.defaultCompany = data.defaultCompany;
          } else {
            // TODO 获取配置的默认站点，访问站点
            const group = this.site.groups[0];
            if (group) {
              this.site.defaultCompany = group;
            }
          }
        })
        .catch(() => {
          this.loading = false;
          // 右下角提示改为ajax 403 统一处理
          // if (error.data) {
          //   this.$notice.operateError('获取用户的景区和集团列表失败', error.data.message);
          // } else {
          //   // 判断http code
          //   this.$notice.operateError('获取用户的景区和集团列表失败', '后台异常');
          // }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@include b(frame) {
  position: relative;
  height: 100%;
  .frame-btn-box {
    position: absolute;
    z-index: 99;
    top: 58px;
    right: 30px;
    display: flex;
    align-items: center;
    height: 42px;
    .frame-btninfo {
      height: 24px;
      border: 1px solid #e0e1e9;
      border-radius: 12px;
      color: #a1a2b2;
      line-height: 22px;
      padding: 0 10px;
      margin-left: 10px;
      cursor: pointer;
      background-color: #ffffff;
    }
  }
}

.container {
  height: calc(100% - 60px);
  width: 100%;
  border-width: 0;
  display: flex;
}
</style>
